import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

import FirstAppointmentSVG from "../assets/svgs/first-app.svg"
import TreatmentProposalSVG from "../assets/svgs/proposal-treatment.svg"
import TreatmentSVG from "../assets/svgs/treatment.svg"
import MaintenanceSVG from "../assets/svgs/maintenance.svg"

const SecondPage = () => (
  <Layout sectionTitle={"Behandelingsverloop"}>
    <SEO title="Behandelingsverloop" />
    <Container className="text-justify">
      <Row id="eerste-consultatie" className="mx-auto alinea-padding">
        <Col md={4} className="my-auto">
          <FirstAppointmentSVG />
        </Col>
        {
          // TODO Illustratie veranderen, staat lelijk ventje
        }
        <Col md className="my-auto">
          <h2>Eerste consultatie - steeds op afspraak</h2>
          <p>
            Meestal is het je tandarts die tijdig opmerkt of je al dan niet een
            orthodontische behandeling nodig hebt. Hij/zij zal je dan motiveren
            een orthodontist te raadplegen. Het is natuurlijk ook mogelijk om op
            eigen initiatief bij een orthodontist langs te gaan. Iedereen is
            vrij om zelf een orthodontist te kiezen. Het is wel handig als deze
            bij jou in de buurt gevestigd is, je moet er tenslotte regelmatig op
            controle gaan. Tijdens het eerste bezoek zal de orthodontist
            beoordelen of je al dan niet een beugel nodig hebt, en zo ja,
            wanneer de behandeling dan het best wordt gestart. Er worden
            allerlei gegevens verzameld van de patiënt (algemene en klinische
            gegevens, gebitsafdrukken en röntgenfoto&#39;s) om een zo nauwkeurig
            mogelijk behandelingsplan te kunnen opstellen.
          </p>
        </Col>
      </Row>

      <Row id="planbespreking" className="mx-auto alinea-padding">
        <Col className="my-auto">
          <h2>Tweede consultatie - planbespreking</h2>
          <p>
            De orthodontist heeft al de verzamelde gegevens bestudeerd en heeft
            nu een behandelingsplan (eventueel verscheidene) opgesteld. Tijdens
            dit consult wordt het probleem van de patiënt toegelicht en wordt
            het behandelingsplan voorgesteld. Er kan een schatting gedaan worden
            van de totale behandelingsduur en de kostprijs wordt exact
            meegedeeld. Positieve orthodontische resultaten kunnen het best
            bereikt worden met goed geïnformeerde en goed medewerkende
            patiënten. Indien je tijdens dit consult nog vragen hebt over de
            behandeling, durf deze dan zeker te stellen. Je krijgt een aanvraag
            voor terugbetaling van de orthodontische behandeling mee en deze
            moet vóór de vijftiende verjaardag ingediend worden bij jouw
            ziekenfonds.
          </p>
        </Col>
        <Col md={4} className="my-auto order-first order-md-last">
          <TreatmentProposalSVG />
        </Col>
      </Row>

      <Row id="behandeling" className="mx-auto alinea-padding">
        <Col md={4} className="my-auto">
          <TreatmentSVG />
        </Col>
        <Col md className="my-auto">
          <h2>Actieve orthodontische behandeling</h2>
          <p>
            De patiënt is niet verplicht de behandeling te laten uitvoeren. Een
            correctie op jonge leeftijd biedt wel een aantal voordelen. Zo zal
            een behandeling vaak minder ingrijpend zijn dan op volwassen
            leeftijd (de groei kan benut worden), de behandelingsduur is korter,
            de patiënt kan terugbetaling genieten door het ziekenfonds en de
            jonge leeftijd maakt de aanvaarding van de beugel makkelijker.
            Orthodontie werkt met subtiele middelen, langzaam maar zeker worden
            de tanden verplaatst en rechtgezet. Een orthodontische behandeling
            duurt gemiddeld 24 maanden. Patiënten komen meestal om de 4 tot 6
            weken langs voor een controle. Tijdens iedere fase van de
            behandeling is de inzet van de patiënt van het grootste belang voor
            het bereiken van de beste resultaten. Alle instructies voor het
            dragen van de beugel en eventuele elastieken moeten écht heel
            nauwkeurig worden opgevolgd. Een goede mondhygiëne is van groot
            belang. De tanden en beugels moeten na elke eetbeurt grondig
            gepoetst worden.
          </p>
        </Col>
      </Row>

      <Row id="retentie" className="mx-auto alinea-padding">
        <Col md className="my-auto">
          <h2>Retentiefase</h2>
          <p>
            De retentiefase wordt ook wel de periode van &apos;passieve&apos;
            behandeling genoemd. Deze fase begint op het moment dat de actieve
            tandverplaatsing beëindigd wordt. Elke patiënt krijgt dan passieve
            apparatuur om de gecorrigeerde tandstand te handhaven. Uitneembare
            retentie-apparatuur (een verhemelteplaatje) kan op termijn meestal
            geleidelijk worden verminderd in draagtijd. Vaste
            retentie-apparatuur (een gekleefde spalk achter de tanden) wordt zo
            lang mogelijk behouden.
          </p>
        </Col>
        <Col md={4} className="my-auto order-first order-md-last">
          <MaintenanceSVG />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
